import { PropertyOnboarding } from "@global";
import { Container, Section } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import { PageContext } from "@util/types";
import { PageWidthWrapper } from "@components/hero";
import React from "react";
import { Query } from "@graphql-types";
import { useStore } from "@state/store";
import RegisterForm from "@shared/drawer/registerForm";
import Account from "@shared/drawer/account";
import styled from "styled-components";
import { colors } from "@util/constants";
import { mediaQuery } from "@styles/mediaQueries";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
  location: any;
}
const Sell = ({ data }: Props) => {
  // const urlParams = new URLSearchParams(window.location.search);
  // const hasPackageID = urlParams.has("packageID");
  // const packageID = urlParams.get("packageID");
  const { user } = useStore();

  if (!user) {
    return (
      <PageWidthWrapper hasImage={false}>
        <Container
          width="80%"
          margin="auto"
          padding="20px"
          flexDirection="column"
          mobileWidth="100%"
        >
          <h1>Add property</h1>

          <Wrapper>
            <h2>Please login to add a property</h2>
            <Account isCart={false} />
          </Wrapper>
        </Container>
      </PageWidthWrapper>
    );
  }

  const pageContent = data?.allSanityOnboarding?.nodes?.[0];

  return (
    <>
      <PageWidthWrapper hasImage={false}>
        <Container width="80%" margin="auto" padding="20px" mobileWidth="100%">
          <h1>Add property</h1>
        </Container>
      </PageWidthWrapper>
      <Section>
        <PropertyOnboarding pageData={pageContent} />
      </Section>
    </>
  );
};

export default Sell;

const Wrapper = styled.div`
  width: 50%;
  padding: 50px;
  margin: auto;
  background-color: #f5f5f5;

  ${mediaQuery.tabletDown} {
    width: 100%;
    padding: 20px;
  }

  h2,
  h4,
  .login-button,
  .login-button svg,
  input {
    color: ${colors.navy};
  }
`;

export const query = graphql`
  {
    allSanityOnboarding {
      nodes {
        _id
        addressHeading {
          ...sanityBlockContent
        }
        featuresHeading {
          ...sanityBlockContent
        }
        parkingHeading {
          ...sanityBlockContent
        }
        priceHeading {
          ...sanityBlockContent
        }
        propertyInfoHeading {
          ...sanityBlockContent
        }
        propertyTypes {
          title

          propertyTypes {
            _key
            _type
            title
          }
          _type
          _key
        }
        personalInformationHeading {
          ...sanityBlockContent
        }
        propertyDescriptionHeading {
          ...sanityBlockContent
        }
        chatGPTPromptText {
          ...sanityBlockContent
        }

        propertyListingTitleHelpText
        shortDescriptionHelpText
      }
    }
  }
`;
